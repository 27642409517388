import FullScreenPresenter from '@theme/components/utils/FullScreenPresenter'

export default {
  name: 'BranchSelectorPPL',
  components: { FullScreenPresenter },
  props: {
    shipper: Object,
  },
  data() {
    return {
      isLoaded: false,
    }
  },
  computed: {
    countriesAvailable() {
      return this.$themeSettings.components.BranchSelectorPPL.countriesAvailable
    },
    country() {
      const countryObject = this.$store.state.globals.countries.find(
        ({ alpha3 }) => alpha3 === this.$store.state.cart.input.country,
      )

      return countryObject?.alpha2.toLowerCase()
    },
    hiddenPoints() {
      return this.$themeSettings.components.BranchSelectorPPL.hiddenPoints
    },
    widgetLanguage() {
      return ['cs', 'sk'].includes(this.$i18n.locale) ? 'cs' : 'en'
    },
    scriptId() {
      return 'ppl-selector-script'
    },
    styleId() {
      return 'ppl-selector-style'
    },
  },
  beforeMount() {
    this.onBeforeMount()
  },
  beforeDestroy() {
    this.onBeforeDestroy()
  },
  methods: {
    async change(value) {
      const branch = await this.$store.dispatch('cart/GET_STORE_BRANCH_BY_REMOTE_ID', {
        shipperId: this.shipper.id,
        remoteId: Number(value),
      })
      this.$store.commit('cart/SET_BRANCH', branch)
    },
    loadBranchSelector() {
      const script = document.createElement('script')
      script.async = true
      script.id = this.scriptId
      script.src = 'https://www.ppl.cz/sources/map/main.js'
      script.addEventListener('load', () => {
        this.isLoaded = true
        this.$emit('init')
      })

      const style = document.createElement('link')
      style.href = 'https://www.ppl.cz/sources/map/main.css'
      style.id = this.styleId
      style.rel = 'stylesheet'

      document.body.appendChild(script)
      document.body.appendChild(style)
    },
    processBranchId(branch) {
      let code = branch?.code

      if (this.$themeSettings.components.BranchSelectorPPL.stripBranchCode) {
        code = code.replace('KM', '')
      }

      return code
    },
    async onBranchChange(event) {
      if (event.detail?.code) {
        const branchId = this.processBranchId(event.detail)
        const branch = await this.$store.dispatch('cart/GET_STORE_BRANCH_BY_REMOTE_ID', {
          shipperId: this.shipper.id,
          remoteId: branchId,
        })
        this.$store.commit('cart/SET_BRANCH', branch)
      }

      this.hide()
    },

    onBeforeMount() {
      document.addEventListener('ppl-parcelshop-map', this.onBranchChange)
    },
    onBeforeDestroy() {
      document.removeEventListener('ppl-parcelshop-map', this.onBranchChange)
    },

    open() {
      this.loadBranchSelector()
      window.scrollTo(0, 0)
      this.$refs.modal.show()
    },
    hide() {
      document.getElementById(this.scriptId)?.remove()
      document.getElementById(this.styleId)?.remove()
      this.$refs.modal.hide()
    },
  },
}
